const initState = {
  feedbackList: [],
  feedbackCount: 0,
  instantfeedbackList: [],
  instantCount: 0,
};

const InternFeedbackReducer = (state = initState, action = {}) => {
  Object.freeze(state);
  if (action.type === "INTERNS/FEEDBACK_LIST") {
    return {
      ...state,
      feedbackList: action.payload.feedbackList,
      feedbackCount: action.payload.feedbackCount,
    };
  } else {
    return state;
  }
};

export default InternFeedbackReducer;
