const INITIAL_STATE = {
  LaunchpadDetail: null,
  OverviewCount: 0,
  LaunchpadResourceCount: 0,
};

const OverviewReducer = (state = INITIAL_STATE, action = {}) => {
  Object.freeze(state);

  if (action.type === "LAUNCHPAD/ORG_OVERVIEW_LIST") {
    return {
      ...state,
      LaunchpadDetail: action.payload.LaunchpadDetail,
      LaunchpadResourceCount: action.payload.LaunchpadResourceCount,
    };
  } else {
    return state;
  }
};

export default OverviewReducer;
