const DepartmentReducer = (
  state = {
    departmentList: [],
    departmentCount: 0,
    orgdepartmentList: [],
    allDepartmentList: [],
    orgdepartmentCount: 0,
  },
  action = {}
) => {
  Object.freeze(state);
  switch (action.type) {
    case "DEPARTMENT/DEPARTMENT_LIST":
      return {
        ...state,
        departmentList: action.payload.departmentList,
        departmentCount: action.payload.departmentCount,
      };
    case "DEPARTMENT/ORG_DEPARTMENT_LIST":
      return {
        ...state,
        orgdepartmentList: action.payload.orgdepartmentList,
        orgdepartmentCount: action.payload.orgdepartmentCount,
      };
    // break;
    case "DEPARTMENT/REMOVE_DEPARTMENT":
      return {
        ...state,
        departmentList: state.departmentList.filter(
          (dept) => dept.department_id !== action.payload.dept.department_id
        ),
        departmentCount: state.departmentCount - 1,
      };
    // break;
    case "DEPARTMENT/DEPARTMENT_DETAILS":
      return {
        ...state,
        departmentDetail: action.payload.departmentDetail,
      };
    case "DEPARTMENT/ALL_DEPARTMENT_LIST":
      return {
        ...state,
        allDepartmentList: action.payload.allDepartmentList,
      };
    case "MANAGERS/ALL_MANAGERS_LIST":
      return {
        ...state,
        allManagersListcount: action.payload.allManagersListcount,
        allManagersList: action.payload.allManagersList,
      };
    // break;

    default:
      return state;
  }
};

export default DepartmentReducer;
