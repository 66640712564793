const SupervisorReducer = (
  state = { projectList: [], projectCount: 0 },
  action = {}
) => {
  Object.freeze(state);

  if (action.type) {
    return state;
  }
};

export default SupervisorReducer;
