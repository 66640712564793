const OrganizationReducer = (
  state = {
    internDetail: {},
    userDetail: {},
    adminUserDetail: {},
    departmentSelectList: [],
    orgList: [],
    orgCount: 0,
    organizationList: [],
    adminDetail: {},
    categoryList: [],
    categoryCount: 0,
    managerDetail: {},
    parentDetail: {},
    userAdminList: [],
    userAdminReceData: [],
    userAdminSkill: [],
    userGenderList: [],
    userLanguageList:[],
    userAdminIntrest: [],
    bioQuestionResponedList: [],
    allBioQuestionList: [],
    userParticipantList: [],
    userParticipantCount: 0,
    deptid: "",
    deptname: "",
    sort_dir: "asc",
    userCommunityList: [],
  },
  action = {}
) => {
  Object.freeze(state);

  switch (action.type) {
    case "ORGANIZATION/ADMINISTRATORS_COMMUNITY_LIST":
      return {
        ...state,
        administratorsList: action.payload.administratorsList,
        administrators_count: action.payload.administrators_count,
      };
    case "ORGANIZATION/TEAM_MEMBERS_COMMUNITY_LIST":
      return {
        ...state,
        teammembersList: action.payload.teammembersList,
        teammembersList_count: action.payload.teammembersList_count,
      };
    case "ORGANIZATION/USERS_COMMUNITY_LIST":
      return {
        ...state,
        userCommunityList: action.payload.teammembersList,
        userCommunityList_count: action.payload.teammembersList_count,
      };
    case "ORGANIZATION/ORGANIZATION_DETAILS":
      return {
        ...state,
        organizationDetail: action.payload.organizationDetail,
      };
    case "ORGANIZATION/INTERNS_LIST":
      return {
        ...state,
        internList: action.payload.internList,
        internCount: action.payload.internCount,
      };

    case "ORGANIZATION/OGANIZATION_LIST":
      return {
        ...state,
        orgList: action.payload.orgList,
        orgCount: action.payload.orgCount,
      };
    // break;
    case "ORGANIZATION/REMOVE_ORGANIZATION":
      return {
        ...state,
        orgList: state.orgList.filter(
          (org) => org.org_id !== action.payload.org.org_id
        ),
        orgCount: state.orgCount - 1,
      };

    case "ORGANIZATION/ALL_OGANIZATION_LIST":
      return {
        ...state,
        organizationList: action.payload.organizationList,
      };

    case "ORGANIZATION/ADMIN_DETAILS":
      return {
        ...state,
        adminDetail: action.payload.adminDetail,
      };
    case "ORGANIZATION/MANAGER_DETAILS":
      return {
        ...state,
        managerDetail: action.payload.managerDetail,
      };
    // break;
    // case 'ORGANIZATION/WORKER_DETAILS':
    // return {
    //   ...state,
    //   workerDetail: action.payload.workerDetail
    // }
    // break;
    // case 'ORGANIZATION/APPLICATION_LIST':
    //   return {
    //       ...state,
    //       applicationList: action.payload.applicationList,
    //       applicationCount: action.payload.applicationCount,

    //   }
    //   break;
    // case 'ORGANIZATION/APPLICATION_DETAILS':
    //   return {
    //     ...state,
    //     applicationDetails: action.payload.applicationDetails
    //   }
    //   break;
    // case 'ORGANIZATION/REMOVE_APPLICATION':
    //   return {
    //       ...state,
    //       applicationList: state.applicationList.filter(app => app.application_id !== action.payload.app.application_id),
    //       applicationCount: state.applicationCount - 1,
    //   }
    //   break;
    case "SIDEBAR/FEATURES_LIST":
      return {
        ...state,
        FeaturesDetails: action.payload.FeaturesDetails,
      };
    // break;
    // case 'ORGANIZATION/SCREEN_LIST':
    //   return {
    //       ...state,
    //       screenList: action.payload.screenList,
    //       screenCount: action.payload.screenCount,

    //   }
    //   break;
    // case 'ORGANIZATION/SCREENS_BY_APPLICATION_ID':
    //   return {
    //       ...state,
    //       screens: action.payload.screens,

    //   }
    //   break;
    // case 'ORGANIZATION/SCREEN_DETAILS_BY_ID':
    //   return {
    //     ...state,
    //     screenDetails: action.payload.screenDetails
    //   }
    //   break;
    case "ORGANIZATION/INTERN_DETAILS":
      return {
        ...state,
        internDetail: action.payload.internDetail,
      };
    // break;
    case "ORGANIZATION/COMMUNITY_USER_DETAILS":
      return {
        ...state,
        userDetail: action.payload.userDetail,
      };
    // break;
    case "ORGANIZATION/ALL_ADMIN_COMMUNITY_USER_DETAILS":
      return {
        ...state,
        adminUserDetail: action.payload.adminUserDetail,
      };
    // break;
    case "ORGANIZATION/CATEGORY_LIST":
      return {
        ...state,
        categoryList: action.payload.categoryList,
        categoryCount: action.payload.categoryCount,
      };
    case "ORGANIZATION/PARENTS_DETAILS":
      return {
        ...state,
        parentDetail: action.payload.parentDetail,
      };

    case "ORGNIGATION/DELET_ORGENGAGECATEGORY_LIST":
      return {
        ...state,
        categoryList: state.categoryList.filter(
          (orgengage) =>
            orgengage.Categoryid !== action.payload.orgengage.Categoryid
        ),
        categoryCount: state.categoryCount - 1,
      };

    case "ORGANIZATION/USER_ADMIN_SCHOOL_LIST":
      return {
        ...state,
        userAdminList: action.payload.userAdminList.UserSchool,
      };
    case "ORGANIZATION/USER_ADMIN_GENDER_LIST":
      return {
        ...state,
        userGenderList: action.payload.userGenderList.UserGender,
      };
      case "ORGANIZATION/USER_LANGUAGE_LIST":
        return {
          ...state,
          userLanguageList: action.payload.userLanguageList.languages,
        };

    case "ORGANIZATION/USER_ADMIN_RACE_LIST":
      return {
        ...state,
        userAdminReceData: action.payload.userAdminReceData,
      };

    case "ORGANIZATION/USER_ADMIN_SKILLS_LIST":
      return {
        ...state,
        userAdminSkill: action.payload,
      };

    case "ORGANIZATION/USER_ADMIN_INTEREST_LIST":
      return {
        ...state,
        userAdminIntrest: action.payload,
      };
    case "ORGANIZATION/BIO_QUESTION_DETAILS":
      return {
        ...state,
        bioQuestionResponedList: action.payload.bioQuestionResponedList,
      };
    case "ORGANIZATION/ALL_BIO_QUESTION_DETAILS":
      return {
        ...state,
        allBioQuestionList: action.payload.allBioQuestionList,
      };
    case "ORGANIZATION/GET_PARTICIPANT_LIST":
      return {
        ...state,
        userParticipantList: action.payload.userParticipantList,
        userParticipantCount: action.payload.userParticipantCount,
      };

    case "SET_COMMUNITY_FILTER":
      return {
        ...state,
        deptname: action.payload.deptname,
        deptid: action.payload.deptid,
        sort_dir: action.payload.sort_dir,
      };
    default:
      return state;
  }
};

export default OrganizationReducer;
