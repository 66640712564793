const INITIAL_STATE = {
  isLoading: false,
  isTransparentLoading: false,
  isVideoLoading: false,
};

const LayoutReducer = (state = INITIAL_STATE, action) => {
  Object.freeze(state);
  switch (action.type) {
    case "LAYOUT/CHANGE_LOADER":
      return {
        ...state,
        isLoading: action.payload.loaderState,
      };
    case "LAYOUT/CHANGE_LOADER_Transparent":
      return {
        ...state,
        isTransparentLoading: action.payload.loaderState,
      };

    case "LAYOUT/CHANGE_LOADER_VIDEO":
      return {
        ...state,
        isVideoLoading: action.payload.loaderState,
      };
    default:
      return state;
  }
};

export default LayoutReducer;
