const UserMasterLogsReducer = (
  state = {
    department_data_log: [],
    main_responsedata_log: [],
    password_data_log: [],
    logsCount: 0,
  },
  action = {}
) => {
  Object.freeze(state);

  switch (action.type) {
    case "ORGANIZATION/OGANIZATION_LIST":
      return {
        ...state,
        department_data_log: action.payload.department_data_log,
        main_responsedata_log: action.payload.main_responsedata_log,
        password_data_log: action.payload.password_data_log,
        logsCount: action.payload.logsCount,
      };

    // break;

    default:
      return state;
  }
};

export default UserMasterLogsReducer;
