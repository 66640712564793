const INITIAL_STATE = {
  projectFeedbackList: [],
  projectFeedbackListInternData: [],
  feedbackCount: 0,
};

const ProjectFeedbackReducer = (state = INITIAL_STATE, action = {}) => {
  Object.freeze(state);

  switch (action.type) {
    case "PROJECT_FEEDBACK_LIST":
      return {
        ...state,
        projectFeedbackList: action.payload.projectFeedbackList,
        feedbackCount: action.payload.feedbackCount,
      };

    case "PROJECT_FEEDBACK_LIST_INTERN":
      return {
        ...state,
        projectFeedbackListInternData: action.payload.projectlist,
        feedbackCount: action.payload.feedbackCount,
      };

    default:
      return state;
  }
};

export default ProjectFeedbackReducer;
