import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";

import userReducer from "./UserReducer";
import organizationReducer from "./OrganizationReducer";
import masterReducer from "./MasterDataReducer";
import SupervisorReducer from "./SupervisorReducer";
import ProjectReducer from "./ProjectReducer";
import InternReducer from "./InternReducer";
import LayoutReducer from "./LayoutReducer";
import InternFeedbackReducer from "./InternFeedbackReducer";
import OverviewReducer from "./OverviewReducer";
import OverviewResourceReducer from "./OverviewResourceReducer";
import DepartmentReducer from "./DepartmentReducer";
import EngageReducer from "./EngageReducer";
import HubReducer from "./HubReducer";

import OrgfeatureslistReducer from "./OrgfeatureslistReducer";
import OrgengagecategoryReducer from "./OrgengagecategoryReducer";
import BioQuestionReducer from "./BioQuestionReducer";
import HelpCenterReducer from "./HelpCenterReducer";
import ProjectFeedbackReducer from "./ProjectFeedbackReducer";

import { reducer as formReducer } from "redux-form";
import CalendarReducer from "./CalendarReducer";
import SettingReducer from "./SettingReducer";
import ResourceCenterReducer from "./ResourceCenterReducer";
import AsanaProjectReducer from "./AsanaProjectReduces";
import userMasterLogsReducer from "./UserMasterLogs";
import SurveyReducers from "./SurveyReducer";
import JourneyReducers from "./journeyReducer";

const RootReducer = combineReducers({
  toastr: toastrReducer,
  userReducer: userReducer,
  organizationReducer: organizationReducer,
  projectReducer: ProjectReducer,
  form: formReducer,
  masterReducer: masterReducer,
  supervisorReducer: SupervisorReducer,
  internReducer: InternReducer,
  layout: LayoutReducer,
  internFeedbackReducer: InternFeedbackReducer,
  overviewReducer: OverviewReducer,
  overviewResourceReducer: OverviewResourceReducer,
  departmentReducer: DepartmentReducer,
  engageReducer: EngageReducer,
  hubReducer: HubReducer,
  orgfeatureslistReducer: OrgfeatureslistReducer,
  orgengagecategoryReducer: OrgengagecategoryReducer,
  bioquestionReducer: BioQuestionReducer,
  helpCenterReducer: HelpCenterReducer,
  projectFeedbackList: ProjectFeedbackReducer,
  CalendarReducer: CalendarReducer,
  SettingReducer: SettingReducer,
  ResourceCenterReducer: ResourceCenterReducer,
  AsanaProjectReducer: AsanaProjectReducer,
  userMasterLogsReducer: userMasterLogsReducer,
  surveyReducers: SurveyReducers,
  journeyReducers: JourneyReducers,
});

export default RootReducer;
