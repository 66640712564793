const initState = {
  supervisorsList: [],
  supervisorCount: 0,
};

const InternReducer = (state = initState, action = {}) => {
  Object.freeze(state);
  if (action.type === "INTERNS/SUPERVISOR_LIST") {
    return {
      ...state,
      supervisorsList: action.payload.supervisorsList,
      supervisorCount: action.payload.supervisorCount,
    };
  } else {
    return state;
  }
};

export default InternReducer;
