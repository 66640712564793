const INITIAL_STATE = {
  calendarList: [],
  meetingList: [],
  selectedUser: [],
  count: 0,
  communityList: {},
  outlookobj: {},
};

const CalendarReducer = (state = INITIAL_STATE, action) => {
  Object.freeze(state);
  switch (action.type) {
    case "LAYOUT/CALENDAR_DATA":
      return {
        ...state,
        calendarList: action.payload,
      };
    case "LAYOUT/MEETING_DATA":
      return {
        ...state,
        meetingList: action.payload,
      };
    case "LAYOUT/EVENTS_DATA":
      return {
        ...state,
        eventList: action.payload,
      };
    case "LAYOUT/COMMUNITY_LIST":
      return {
        ...state,
        communityList: action.payload,
      };
    case "CALENDAR/CALENDAR_EVENT_LIST":
      return {
        ...state,
        calendarEventList: action.payload,
      };
    case "CALENDAR/CALENDAR_EVENT_DETAILS":
      return {
        ...state,
        calendarEventDetails: action.payload,
      };
    case "GET_OUTLOOKEVENT_OBJ":
      return {
        ...state,
        outlookobj: action.payload.outlookobj,
      };

    case "CALENDAR/SURVEY_CALENDAR_EVENT":
      return {
        ...state,
        getSurveyData: action.payload,
      };
    case "CALENDAR/ADMIN_GET_SURVEY_RESULT":
      return {
        ...state,
        adminSurveyResult: action.payload,
      };
    case "CALENDAR/RESET_ITEM":
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default CalendarReducer;
