const INITIAL_STATE = {};
const JourneyReducers = (state = INITIAL_STATE, action) => {
    Object.freeze(state);
    switch (action.type) {
        case "JOURNEY/CHOICE_LIST":
            return {
                ...state,
                journeyChoiceList: action.payload.journeyChoiceList,
            };
        case "JOURNEY/ADMIN_JOURNEY_LIST":
            return {
                ...state,
                getAdminJourneyList: action.payload.getAdminJourneyList,
            };
        case "JOURNEY/DETAILS":
            return {
                ...state,
                journeyDetails: action.payload.journeyDetails,
            };

        case "JOURNEY/STEP_DETAILS":
            return {
                ...state,
                journeyStepDetails: action.payload.journeyStepDetails,
            };
        case "JOURNEY/USERS_LIST":
            return {
                ...state,
                userList: action.payload.userList,
                totalUserCount: action.payload.totalUserCount,
            };
        case "JOURNEY/JOURNEY_PROJECT_LIST":
            return {
                ...state,
                JourneyProjectList: action.payload.JourneyProjectList,
            };
        case "JOURNEY/RESET_ITEM":
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default JourneyReducers;
