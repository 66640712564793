const MasterDataReducer = (
  state = {
    socialNetworksList: [],
    socialNetworksCount: 0,
    countriesList: [],
    statesList: [],
    departmentList: [],
    departmentCount: 0,
    masterReactsList: [],
    masterReactsCount: 0,
    imageRemove: false,
    removeFile: false,
  
  },
  action = {}
) => {
  Object.freeze(state);

  switch (action.type) {
    case "MASTERDATA/COUNTRY_LIST":
      return {
        ...state,
        countriesList: action.payload.countriesList,
      };
    // break;
    case "MASTERDATA/STATE_LIST":
      return {
        ...state,
        statesList: action.payload.statesList,
      };
    // break;
    case "MASTERDATA/CITY_LIST":
      return {
        ...state,
        citiesList: action.payload.citiesList,
      };
    // break;
    case "MASTERDATA/DEPARTMENT_LIST":
      return {
        ...state,
        departmentList: action.payload.departmentList,
        departmentCount: action.payload.departmentCount,
      };
    // break;
    case "MASTERDATA/SOCIALNETWORKS_LIST":
      return {
        ...state,
        socialNetworksList: action.payload.socialNetworksList,
        socialNetworksCount: action.payload.socialNetworksCount,
      };
    // break;
    case "MASTERDATA/SKILLS_LIST":
      return {
        ...state,
        skillsList: action.payload.skillsList,
        skillsCount: action.payload.skillsCount,
      };
    // break;
    case "MASTERDATA/INTERESTS_LIST":
      return {
        ...state,
        interestsList: action.payload.interestsList,
        interestsCount: action.payload.interestsCount,
      };

    case "LAYOUT/REMOVE-IMAGE":
      return {
        ...state,
        imageRemove: action.payload.removeType,
      };

    case "LAYOUT/REMOVE-FILE-VALIDATION":
      return {
        ...state,
        removeFile: action.payload.removeFile,
      };

    case "MASTERDATA/MASTER_REACTIONS_LIST":
      return {
        ...state,
        masterReactsList: action.payload.masterReactsList,
        masterReactsCount: action.payload.masterReactsCount,
      };
    case "MASTERDATA/SCHOOLS_LIST":
      return {
        ...state,
        schoolList: action.payload.schoolList,
        schoolsCount: action.payload.schoolsCount,
      };
    case "MASTERDATA/RACE_LIST":
      return {
        ...state,
        raceList: action.payload.raceList,
        raceCount: action.payload.raceCount,
      };

    case "MASTERDATA/GENDER_LIST":
      return {
        ...state,
        genderList: action.payload.genderList,
        genderCount: action.payload.genderCount,
      };
      case "MASTERDATA/NATIVE_LANGUAGES":
      return {
        ...state,
        languagesList: action.payload.languagesList,
        languagesCount: action.payload.languagesCount,
      };

    default:
      return state;
  }
};

export default MasterDataReducer;
