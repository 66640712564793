const HubReducer = (
    state = {
        announcementList: [],
        announcementCount: 0,
        announcementAssociate: [],
        announcementQuckLinkData: [],
        announcementAssociate_count: 0,
    },
    action = {}
) => {
    Object.freeze(state);

    switch (action.type) {
        case "HUB/GET_ANNOUNCEMENT_LIST":
            return {
                ...state,
                announcementList: action.payload.announcementList,
                announcementCount: action.payload.announcementCount
            };
        case "HUB/GET_MANAGER_INTERN_LIST":
            return {
                ...state,
                mangerInternList: action.payload.mangerInternList,
                mangerInternList_count: action.payload.mangerInternList_count
            };
        case "HUB/GET_ANNOUNCEMENT_QUCKLINK_LIST":
            return {
                ...state,
                announcementQuckLinkData: action.payload.announcementQuckLinkData,
            };

        default:
            return state;
    }
};

export default HubReducer;
