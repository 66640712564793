import localStorageService from "../utils/localStorageService";

const LocalStorageService = localStorageService.getService();
var currentUser = JSON.parse(LocalStorageService.getCurrentUser());
const UserReducer = (
  state = {
    currentUser: currentUser,
    userList: [],
    deactivatedUserList: [],
    userCount: 0,
    deactivatedUserCount: 0,
    adminUserDetail: null,
    utilityData: {},
  },
  action = {}
) => {
  Object.freeze(state);

  switch (action.type) {
    case "USER_GET":
      return {
        ...state,
        currentUser: action.payload.currentUser,
      };
    case "USER/EDIT_USER_UTILITY":
      return {
        ...state,
        utilityData: action.payload.utilityData,
      };
    case "USER_LOGOUT":
      return {
        ...state,
        currentUser: null,
      };
    case "USER/USER_LIST":
      return {
        ...state,
        userList: action.payload.userList,
        userCount: action.payload.userCount,
      };

    case "USER/DEACTIVATED_USER_LIST":
      return {
        ...state,
        deactivatedUserList: action.payload.deactivatedUserList,
        deactivatedUserCount: action.payload.deactivatedUserCount,
      };

    case "USER/USER_DETAILS_BY_ID":
      return {
        ...state,
        UserDetails: action.payload.UserDetails,
      };
    case "INTERN/INTERN_RESPONSE_FEEDBACK":
      return {
        ...state,
        internInfo: action.payload.internInfo,
      };
    default:
      return state;
  }
};

export default UserReducer;
