const INITIAL_STATE = {};

const SurveyReducers = (state = INITIAL_STATE, action) => {
    Object.freeze(state);
    switch (action.type) {
        case "SURVEY/USER_LISTS":
            return {
                ...state,
                userList: action.payload.userList,
            };
        case "SURVEY/SURVEY_DEFAULT_LIST":
            return {
                ...state,
                surveyDefaultList: action.payload.surveyDefaultList,
                surveyDefaultCount: action.payload.surveyDefaultCount,
                surveyDefaultRecipients: action.payload.surveyDefaultRecipients,
                surveyDefaultRecipients_Count: action.payload.surveyDefaultRecipients_Count,
            };
        case "SURVEY/SURVEY_LIST":
            return {
                ...state,
                surveyListcount: action.payload.surveyListcount,
                surveyList: action.payload.surveyList,
            };
        case "SURVEY/RECIPIENT_LIST":
            return {
                ...state,
                surveyRecipientcount: action.payload.surveyRecipientcount,
                surveyRecipientList: action.payload.surveyRecipientList,
            };
        case "SURVEY/SURVEY_GET_RESULT":
            return {
                ...state,
                survey_recipantData: action.payload.survey_recipantData,
            };
        case "SURVEY/SURVEY_OTHER_USER_LIST":
            return {
                ...state,
                surveyOtherList: action.payload.surveyOtherList,
                otherSurveyListCount:action.payload.otherSurveyListCount
            };
        case "SURVEY/SURVEY_MANAGER_SURVEY_LIST":
            return {
                ...state,
                surveyManagerList: action.payload.surveyManagerList,
                ManagersurveyListcount:action.payload.ManagersurveyListcount
            };
        case "SURVEY/SURVEY_GET_QUESTION":
            return {
                ...state,
                surveyGetCount: action.payload.surveyGetCount,
                surveyGetQuestions: action.payload.surveyGetQuestions,
                surveyGetRecipients: action.payload.surveyGetRecipients,
            };
        case "SURVEY/EXPORT_SURVEY_RESULT":
            return {
                ...state,
                export_result: action.payload.export_result
            };
        case "GET_RECIPIENT_COUNT":
            return {
                ...state,
                recipientCount: action.payload.recipientCount
            };
        case "SURVEY/RESET_ITEM":
            return INITIAL_STATE;
        default:
            return state;
    }
}; 

export default SurveyReducers;
