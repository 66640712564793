/* eslint-disable */

const AsanaProjectReducer = (
  state = {
    AsanaProjectCount: 0,
    AsanaProjectList: [],
    AsanaCollaboratedProjectList: [],
    AsanaCollaboratedProjectCount: 0,
  },
  action = {}
) => {
  Object.freeze(state);

  switch (action.type) {
    case "PROJECTS/ADMIN_ASANA_PROJECTS_LIST":
      return {
        ...state,
        AsanaProjectList: action.payload.AsanaProjectList,
        AsanaProjectCount: action.payload.AsanaProjectCount,
      };
    case "PROJECTS/ADMIN_ASANA_COLLABORATED_PROJECTS_LIST":
      return {
        ...state,
        AsanaCollaboratedProjectList:
          action.payload.AsanaCollaboratedProjectList,
        AsanaCollaboratedProjectCount:
          action.payload.AsanaCollaboratedProjectCount,
      };
    default:
      return state;
  }
};

export default AsanaProjectReducer;
