import { createStore, applyMiddleware, compose } from "redux";

import RootReducer from "../reducers/root_reducer";

import thunk from "redux-thunk";
import { createLogger } from "redux-logger";

export function configureStore(initialState) {
  const store = createStore(
    RootReducer,
    initialState,
    compose(
      applyMiddleware(
        thunk,
        createLogger({
          collapsed: true,
          duration: true,
          timestamp: false,
        })
      )
    )
  );

  if (module.hot) {
    module.hot.accept("../reducers/root_reducer", () => {
      const nextRootReducer = require("../reducers/root_reducer");
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
