const INITIAL_STATE = {
  emailNotificationStatus: "",
  slackNotificationStatus: "",
  magicbellNotificationStatus: "",
  orgIsSlack: "",
  getBioQuationList: [],
  orgcommunityquote: [],
  org_image: {},
};

const SettingReducer = (state = INITIAL_STATE, action) => {
  Object.freeze(state);
  switch (action.type) {
    case "SETTING/NOTIFICATION_SETTING_STATUS":
      return {
        ...state,
        emailNotificationStatus: action.payload.emailNotificationStatus,
        slackNotificationStatus: action.payload.slackNotificationStatus,
        magicbellNotificationStatus: action.payload.magicbellNotificationStatus,
        orgIsSlack: action.payload.orgIsSlack,
      };

    case "SETTING/GET_BIOQUATION":
      return {
        ...state,
        getBioQuationList: action.payload.getBioQuationList,
      };

    case "SETTING/GET_ORGCOMMUNITYQUOTE":
      return {
        ...state,
        orgcommunityquote: action.payload.orgcommunityquote,
      };

    case "SETTING/GET_COMPANY_LOGO":
      return {
        ...state,
        org_image: action.payload.org_image,
      };
    case "SETTING/DASHBOARD_LIST":
      return {
        ...state,
        dashboardList: action.payload.dashboardList,
      };
    default:
      return state;
  }
};

export default SettingReducer;
